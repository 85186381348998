import React, {useState, useEffect} from "react";
import classes from "./About.css";
import Gallery from "react-photo-gallery";
import {photoSet1, photoSet2, photoSet3, photoUrlSet1, photoUrlSet2, photoUrlSet3} from "../../components/photos";
import FsLightbox from 'fslightbox-react';
import Fade from "react-reveal/Fade";


function About() {
const [toggler1, setToggler1] = useState(false);
const [slide1, setSlide1] = useState(1);
const [slide2, setSlide2] = useState(1);
const [toggler2, setToggler2] = useState(false);
const [slide3, setSlide3] = useState(1);
const [toggler3, setToggler3] = useState(false);


const [isLoaded, setIsLoaded] = useState(false)
useEffect(() => { setIsLoaded(true)}, []);
const style = isLoaded ? {} : {visibility: 'hidden'}

    return (
        <>
         <div className={classes.About}>

             <div className={classes.DesktopContainer}>
                <Fade bottom>
                    <h1>Building things <br /> for the web and more</h1>
                </Fade>
             </div>

             <div className={classes.MobileContainer}>
                    <h1>Building things <br /> for the web and more</h1>
             </div>
             
            <p>I've been started taking interest in Computer Science when I was about 20. After a few years, when I realized that I liked programming, and that I could build a carrier on top of it,
                I changed my University studies, and learned the basics of programming languages, computer paradigms, and all the basic stuff that a programmer should know <span role="img" aria-label="smiling">😄</span>.
            </p>
            <p>After my degree, I started immediately to work in the nearest big city (Catania) near my hometown (Milazzo), and since then improved my capacities, deciding that what I wanted to do was getting 
                better and better on the front end aspect of the development.
            </p>

            <div className={classes.DesktopContainer}>
                <Fade bottom duration={1200}>
                    <div className={classes.PhotoContainer}>
                        <Gallery photos={photoSet1} onClick={(event, { index }) => { setSlide1(index + 1); setToggler1(!toggler1) }} style={style} />
                    </div>   
                </Fade>
            </div>

            <div className={classes.MobileContainer}>
                <div className={classes.PhotoContainer}>
                    <Gallery photos={photoSet1} onClick={(event, { index }) => { setSlide1(index + 1); setToggler1(!toggler1) }} style={style} />
                </div>     
            </div>
                  
            <FsLightbox toggler={toggler1} sources={photoUrlSet1} slide={slide1} className={classes.LightBox}/>
            <p>
                In these years, I designed and implemented several web applications for both public and private companies, in addition to the development of websites, e-commerces, and a few full stack projects.
                I like and know pretty well Javascript, TypeScript, and all the most popular frameworks that work on these languages: Angular, React, and Vue.js.
            </p>
            <p>I also spend a good portion of my free time to continuosly keep improving my knowledge of these tools, and of all the new things that keep the world of 
                technology interesting and worthy of being discovered <span role="img" aria-label="smiling">🧑‍💻</span>.</p>

            <div className={classes.DesktopContainer}>
                <Fade bottom duration={1200}>
                    <div className={classes.PhotoContainer}>
                        <Gallery photos={photoSet2} onClick={(event, { index }) => { setSlide2(index + 1); setToggler2(!toggler2) }} style={style} />
                    </div>
                </Fade>
            </div>

            <div className={classes.MobileContainer}>
                <div className={classes.PhotoContainer}>
                    <Gallery photos={photoSet2} onClick={(event, { index }) => { setSlide2(index + 1); setToggler2(!toggler2) }} style={style} />
                </div>
            </div>
            
            
            <FsLightbox toggler={toggler2} sources={photoUrlSet2} slide={slide2} className={classes.LightBox}/>
            
            <p>
                Apart from that, I like animals, nature, good food, and spending time with my girlfriend and my friends. 
                I really enjoy a good book or a good movie, and I basically constantly listen to Spotify during my day.
                I also like to get up early, make each day productive, and enjoy the simple things in life.
            </p>

            <div className={classes.DesktopContainer}>
                <Fade bottom duration={1200}>
                    <div className={classes.PhotoContainer}>
                        <Gallery photos={photoSet3} onClick={(event, { index }) => { setSlide3(index + 1); setToggler3(!toggler3) }} style={style} />
                    </div>
                </Fade>
            </div>

            <div className={classes.MobileContainer}>
                <div className={classes.PhotoContainer}>
                    <Gallery photos={photoSet3} onClick={(event, { index }) => { setSlide3(index + 1); setToggler3(!toggler3) }} style={style} />
                </div>
            </div>
          
           
            <FsLightbox toggler={toggler3} sources={photoUrlSet3} slide={slide3} className={classes.LightBox}/>
            
            </div>
            
        </>
    );
}

export default About;