import React from "react";
import classes from "./ProjectsTable.css";
import GitSvgWhite from "../../assets/svgs/gitSvgWhite";
import ExternalSvgWhite from "../../assets/svgs/externalSvgWhite";
import Fade from "react-reveal/Fade";

const projectsTable = () => {
    const projects = [
        {
            title: "Posters Shop",
            built: ["React", "Node", "MongoDB"],
            link: {
                git: "https://github.com/acaravello/fullstack-posters-project"
            }
        },
        {
            title: "Cocktails from the World",
            built: ["Html & Css", "Vanilla JavaScript", "API"],
            link: {
                website: "https://cocktail-api-js.web.app",
                git: "https://github.com/acaravello/cocktail-app"
            }
        },
        {
            title: "Lights Out",
            built: ["React", "State & Props"],
            link: {
                website: "https://lights-out-game-36e3b.web.app",
                git: "https://github.com/acaravello/lights-out-game"
            }
        },
        {
            title: "Yahtzee",
            built: ["React", "State & Props"],
            link: {
                website: "https://yahtzee-game-587bc.web.app/",
                git: "https://github.com/acaravello/yahtzee-game"
            }
        },
        {
            title: "Material Workout",
            built: ["Angular Material", "NgRx", "Firestore"],
            link: {
                website: "https://angular-material-ngrx-a8e3c.web.app/login",
                git: "https://github.com/acaravello/daily-workout-material"
            }
        },
        {
            title: "Palette Generator",
            built: ["React", "Material UI", "JSS"],
            link: {
                website: "https://color-palette-c259a.web.app/",
                git: "https://github.com/acaravello/palette-creator"
            }
        },
        {
            title: "Spotify Stats",
            built: ["React", "API", "Axios"],
            link: {
                website: "https://spotify-stats-57959.web.app",
                git: "https://github.com/acaravello/spotify-stats"
            }
        },
        {
            title: 'Snake',
            built: ['HTML', 'Vanilla JS'],
            link: {
                website: 'https://snake-vanilla-app.web.app',
                git: 'https://github.com/acaravello/snake-vanilla'
            }
        },
        {
            title: "VueMDB",
            built: ["Vue", "API", "Vuex"],
            link: {
                website: "https://vue-movie-app-cb464.web.app",
                git: "https://github.com/acaravello/vue-movie-app"
            }
        },
        {
            title: "Angular & Movie DB",
            built: ["Angular", "API", "Material UI"],
            link: {
                website: "https://angular-movie-app-fce92.web.app",
                git: "https://github.com/acaravello/movie-app-angular"
            }
        },
        {
            title: 'NeatNote',
            built: ['Angular', 'Ionic', 'rxjs'],
            link: {
                website: 'https://neatnote-b2455.web.app',
                git: 'https://github.com/acaravello/neatnote'
            }
        }
    ]

    return (
        <div className={classes.projectsTable}>
            <div className={classes.TableContainer}>
                <Fade bottom duration={1200}>
                    <div>
                    <div className={classes.TableHead}>
                        <table>
                            <thead>
                                <tr className={classes.Head}>
                                    <th>Title</th>
                                    <th>Built with</th>
                                    <th>Link</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                
                <div className={classes.TableBody}>
                    <table>
                        <tbody>
                            {projects.map((project, index) => {
                                return (
                                    <tr className={classes.Body} key={index}>
                                        <td>{project.title}</td>
                                        <td>{project.built.map((el, i) => {
                                            return (
                                                <span key={i}>
                                                    <span key={i} className={classes.TechUsed}>{el}</span>
                                                    {i < project.built.length - 1 ? <span className={classes.Separator}>·</span> : null}
                                                </span>
                                            )
                                        }
                                        )}</td>
                                        <td>{project.link.git ? <a href={project.link.git} target="_blank" rel="noopener noreferrer"><GitSvgWhite/></a> : null} {project.link.website ? <a href={project.link.website} target="_blank" rel="noopener noreferrer"><ExternalSvgWhite/></a> : null}</td>
                                    </tr>
                                )
                            }).reverse()}
                        </tbody>
                    </table>
                </div>
                </div>
                </Fade>
            </div>

            <div className={classes.tableForMobile}>
            {projects.map((project, index) => {
                return(
                    <div className={classes.CardContainer} key={index}>
                        <div className="card" style={{borderColor: "transparent", boxShadow: "rgba(2, 12, 27, 0.7) 0 10px 30px -15px"}}>
                            <div className="card-body" style={{ backgroundColor: "#00ad5f", color: "#fff", fontFamily: "Lato Regular", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                <h5 className="card-title" style={{ margin: "0px" }}>{project.title}</h5>
                            </div>
                            <ul className="list-group list-group-flush">

                                
                                    {project.built.map((el, i) => {
                                        return(
                                            <li className="list-group-item" key={i} style={{border:"1px solid rgba(0,173,95,.5)", color: "#fff", backgroundColor: "#030201", fontSize: "13px"}}>
                                                 {el}
                                            </li>
                                        )
                                    })}
                            </ul>
                            <div className="card-body" style={{backgroundColor: "#030201", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px"}}>
                            {project.link.git ? <a href={project.link.git} target="_blank" rel="noopener noreferrer" className="card-link"><GitSvgWhite/></a> : null} {project.link.website ? <a href={project.link.website} target="_blank" rel="noopener noreferrer"><ExternalSvgWhite/></a> : null}
                            </div>
                        </div>
                    </div>   
                )
            }).reverse()}
            </div>
        

        </div>
    )
}

export default projectsTable;