import React from "react";
import AuxElement from "../../../hoc/AuxElement";
import classes from "./Toolbar.css";
import NavigationItems from "../NavigationItems/NavigationItems";

const toolbar = () => {
    return (
        <AuxElement>
            <header className={classes.Toolbar}>
            <nav>
                <NavigationItems />
            </nav>
            </header>
        </AuxElement>
    )
}

export default toolbar;