import React, {Component} from 'react';
import classes from './App.css';
import Homepage from "./components/Homepage/Homepage";
import Footer from "./components/Footer/Footer";
import Toolbar from "./components/Menu/Toolbar/Toolbar";
import {Route, Switch} from "react-router-dom";
import AuxElement from "./hoc/AuxElement";
import About from "./containers/About/About";
import Blog from "./containers/Blog/Blog";
import Projects from "./containers/Projects/Projects";
import SinglePost from "./containers/Blog/SinglePost/SinglePost";

class App extends Component {
  render() {
    return (
      <AuxElement>
        <div className={classes.App}>
          <Toolbar />
          <Switch>
          <Route path="/about" component={About} />
          <Route path="/blog" component={Blog} />
          <Route path="/projects" component={Projects} />
          <Route path="/post/:id" component={SinglePost} />
          <Route path="/" exact component={Homepage} />
          </Switch>
          <Footer />
        </div>
      </AuxElement>    
    );
  }
}


export default App;
