import React, {Component} from "react";
import classes from "./SinglePost.css";
import ReactHtmlParser from "react-html-parser";
import axios from "../../../assets/axios/axios-calling";
import Fade from "react-reveal/Fade"

class SinglePost extends Component {
    constructor(props){
        super(props);
        this.state = {
            articles: [],
            articlePassed: null
        }
        this._isMounted = false;
    }

    componentDidMount() {

        this._isMounted = true;

        if(this.props.match && this.props.match.params) {
            this.setState({idPassed: this.props.match.params.id});
        }

        this._isMounted && axios.get("https://thecaravello-web-app.firebaseio.com/posts.json").then( response => {
            let articlesFetched = []
                 for(let key in response.data) {
                    articlesFetched.unshift({
                         ...response.data[key]
                     })
                 }

                 const updatedPosts = articlesFetched.map( post => {
                    return {
                        ...post
                    }
                });
                this._isMounted && this.setState({articles: updatedPosts});

                if(this.state.articles.length > 0) {
                    for (let i = 0; i < this.state.articles.length; i++) {
                        if(this.state.articles[i].id === +this.state.idPassed) {
                            this.setState({articlePassed: {...this.state.articles[i]}})
                            break;
                        }
                    }
                }
            })
            .catch(error => {
                console.log("Error in retrieving data");
                console.log(error)
            });
        }

    componentWillUnmount() {
        this._isMounted = false;
    }
    
    render() {
        let {title, body, imgHeader} = this.props;
        let passed = false;
       
        if(this.state.articlePassed) {
            title = this.state.articlePassed.title;
            body = this.state.articlePassed.body;
            imgHeader = this.state.articlePassed.imgHeader;
            passed = true;                                                                           
        }
        
        return (
            <div>
                    {this.state.articlePassed ?
                    <div>
                        <div className={classes.HeaderImgContainer}>
                            <img srcSet={imgHeader} alt={title} />
                        </div>
                        <div className={passed ? classes.SinglePostDetail : classes.SinglePost}>
                            <h1>{title}</h1>
                            <div>{ReactHtmlParser(body)}</div>
                        </div>
                    </div> : <div className={passed ? classes.SinglePostDetail : classes.SinglePost}>
                        <div className={classes.DesktopContainer}>
                        <Fade bottom>
                            <h1>{title}</h1>
                        </Fade>
                        </div>
                        <div className={classes.MobileContainer}>
                            <h1>{title}</h1>
                        </div>
                        
                        <div>{ReactHtmlParser(body)}</div>
                    </div>
                    }
                    
            </div>
        )
    }
}


export default SinglePost;