import React, {Component} from "react";
import classes from "./ProjectCard.css";
import GitSvg from "../../assets/svgs/gitSvg";
import ExternalSvg from "../../assets/svgs/externalSvg";


class ProjectCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        }

        this.loadingDone = this.loadingDone.bind(this);
    }

    loadingDone() {
        this.setState({loaded: true});
    }

    render() {
        const {appUrl, title, description, listTech, gitUrl, left, projectImage} = this.props;
        const style = this.state.loaded ? {} : {visibility: 'hidden'}
        return(
            <div className={classes.ProjectCardContainer} style={style}>
            <div className={left? classes.ProjectCardLeft : classes.ProjectCardRight}>
                <h3 className={classes.TitleMobile}>{title}</h3>
                <div className={classes.ImageContainer}>
                    <a href={appUrl} target="_blank" rel="noopener noreferrer">
                    <img src={projectImage} alt={title} onLoad={this.loadingDone}/>
                    </a>
                    </div>
                <div className={classes.DescriptionContainer}>
                    <h3>{title}</h3>
                    <div className={classes.Description}>
                        {description}
                    </div>
                    <ul className={classes.ListTech}>
                        {listTech.map((el, i) => <li key={i}>{el}</li>)}
                    </ul>
                    <div className={classes.iconContainer}>
                        <div className={classes.Icon}>
                            <a href={gitUrl} target="_blank" rel="noopener noreferrer">
                                <GitSvg />
                            </a>
                        </div>
                        <div className={classes.SecondIcon}>
                        <a href={appUrl} target="_blank" rel="noopener noreferrer">
                            <ExternalSvg />
                        </a>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProjectCard;
