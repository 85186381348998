import React, {Component} from "react";
import classes from "./Projects.css";
import ProjectCard from "./ProjectCard";
import project1 from "../../assets/projects/yahtzee_game.png"
import project2 from "../../assets/projects/palette-creator.png";
import project3 from "../../assets/projects/spotify-stats.png";
import project4 from "../../assets/projects/vuemdb.png"
import project5 from "../../assets/projects/angular-and-movie-db.png";
import project6 from '../../assets/projects/neatnote.png';
import ProjectsTable from "../ProjectsTable/ProjectsTable";
import Fade from "react-reveal/Fade"

const projects = [
    {
        appUrl: "https://neatnote-b2455.web.app",
        title: "NeatNote",
        description: "A neat (like the name suggests) application to take notes, based on my favourite note app, Simplenote. Build with Ionic, can be easily transformed into a native app (android/ios)",
        listTech: ['Angular', 'Ionic', 'rxjs'],
        gitUrl: 'https://neatnote-b2455.web.app',
        projectImage: project6
    },
    {
        appUrl: "https://angular-movie-app-fce92.web.app",
        title: "Angular & Movie DB",
        description: "Another implementation of MovieDB API, but written in Angular. Adds some new features compared to Vuemdb, like server-side pagination e sorting, loading animations, and svg rendered as Components.",
        listTech: ["Angular", "API", "Material"],
        gitUrl: "https://github.com/acaravello/movie-app-angular",
        projectImage: project5
    },
    {
        appUrl: "https://vue-movie-app-cb464.web.app",
        title: "Vuemdb",
        description: "Like IMDB, but written entirely in Vue! Thanks to the Movie Database API, you can check latest and popular movies, tv series, tranding people, looking at the cast of something you watched, and discover people's filmography.",
        listTech: ["Vue", "API", "Vuex"],
        gitUrl: "https://github.com/acaravello/vue-movie-app",
        projectImage: project4
    },
    {
        appUrl: "https://spotify-stats-57959.web.app",
        title: "Spotify Stats",
        description: "Thanks to Spotify API, you can log-in with your accounts, and check a bunch on interesting info about what you usually listen to, and what are your favourite song, artists, and what playlists you have saved.",
        listTech: ["React", "API", "Axios"],
        gitUrl: "https://github.com/acaravello/spotify-stats",
        projectImage: project3
    },
    {
        appUrl: "https://color-palette-c259a.web.app/",
        title: "Palette Generator",
        description: "An application that allows users to create and save color palettes. There's a default set already available, a random color generator, variations of color suggested for each color, and a few others funtionalities.",
        listTech: ["React", "Material UI", "JSS"],
        gitUrl: "https://github.com/acaravello/palette-creator",
        projectImage: project2
    },
    {
        appUrl: "https://yahtzee-game-587bc.web.app/",
        title: "Yahtzee",
        description: `Yahtzee is a dice game. On each turn you can roll the dice up to three times, and score a combination.
        To goal is to complete all the thirteen combinations present, doing the highest score possible.`,
        listTech: ["React", "State & Props"],
        gitUrl: "https://github.com/acaravello/yahtzee-game",
        projectImage: project1
    },
];

class Projects extends Component {
    render() {
        return (
            <div className={classes.Projects}>
                <div className={classes.DesktopContainer}>
                    <Fade bottom>
                        <h1 className={classes.ThingsCreated}>Some things I've created</h1>
                    </Fade>
                </div>

                <div className={classes.MobileContainer}>
                        <h1 className={classes.ThingsCreated}>Some things I've created</h1>
                </div>
                
                    <div className={classes.ProjectCards}>
                        {projects.map((project, index) => {
                            return <ProjectCard key={index} appUrl={project.appUrl} title={project.title} projectImage={project.projectImage}
                                description={project.description} listTech={project.listTech} gitUrl={project.gitUrl} left={index % 2 === 0} />
                        })}
                    </div>
              
                    <div className={classes.AllProjectsTable}>
                        <h2 className={classes.TableTitle}>And here's an exhaustive list of things I've worked on:</h2>
                        <ProjectsTable />
                    </div>
            </div>
        )
    }
}

export default Projects;