import React, {Component} from "react";
import classes from './HomepageContent.css';
import AuxElement from "../../../hoc/AuxElement"
import Blog from "../../../containers/Blog/Blog";

class HomepageContent extends Component {
    render() {
        return(
            <AuxElement>
                <div className={classes.HomepageContent}>
                <Blog lastArticle={true}/>
                </div>
            </AuxElement>
        )
    }
}

export default HomepageContent;