import React, {Component} from "react";
import classes from "./Blog.css";
import SinglePost from "./SinglePost/SinglePost";
import AuxElement from "../../hoc/AuxElement";
import axios from "../../assets/axios/axios-calling";
import PreviewPost from "./PreviewPost/PreviewPost";

class Blog extends Component {

    state = {
        articles: []
    }

    componentDidMount() {
        axios.get("https://thecaravello-web-app.firebaseio.com/posts.json").then( response => {
            let articlesFetched = []
                 for(let key in response.data) {
                    articlesFetched.unshift({
                         ...response.data[key]
                     })
                 }

                 const updatedPosts = articlesFetched.map( post => {
                    return {
                        ...post
                    }
                });
                this.setState({articles: updatedPosts});
            })
            .catch(error => {
                console.log("Error in retrieving data");
                console.log(error)
            });
        }

    render() {
        let articles = null;
        let lastArticle = {};
        if (this.state.articles) {
            lastArticle = {...this.state.articles[0]}
            articles = this.state.articles.map(article => {
                return <PreviewPost
                    key={article.id}
                    id={article.id}
                    title={article.title}
                    body={article.body}
                />
            });
        }
        if(this.props.lastArticle) {
            return(
                <SinglePost
                key={lastArticle.id}
                title={lastArticle.title}
                body={lastArticle.body}
                />
            )
        } else {
            return (
                <AuxElement>
                    <div className={classes.Blog}>
                        {articles}
                    </div>
                </AuxElement>
            )
        }
        
    }

}

export default Blog;