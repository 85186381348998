import React from "react";
import classes from "./Homepage.css";
import HomepageHeader from "./HomepageHeader/HomepageHeader";
import HomepageContent from "./HomepageContent/HomepageContent";
import AuxElement from "../../hoc/AuxElement";


const homepage = () => {
    return(
        <AuxElement>
            <div className={classes.Homepage}>
            <HomepageHeader></HomepageHeader>
            <HomepageContent></HomepageContent>
            </div>
        </AuxElement>
    )
}

export default homepage;
