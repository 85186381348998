import React from "react";
import classes from "./HomepageHeader.css";
import AuxElement from "../../../hoc/AuxElement";

const homepageHeader = () => {
    return(
        <AuxElement>
            <div className={classes.HomepageHeader}>
                <div className={classes.Name}>ANTONIO CARAVELLO </div>
                <div className={classes.Separator}>WEB DEVELOPER</div>
                </div>
        </AuxElement>
    )
}

export default homepageHeader;