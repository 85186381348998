import first from "../assets/images/1.jpg";
import second from "../assets/images/2.jpg";
import third from "../assets/images/3.jpg";
import fourth from "../assets/images/4.jpg";
import fifth from "../assets/images/5.jpg";
import seventh from "../assets/images/7.jpg";
import eight from "../assets/images/8.jpg";
import nineth from "../assets/images/9.jpg";
import eleventh from "../assets/images/11.jpg";
import twelfth from "../assets/images/12.jpg";
import thirteenth from "../assets/images/13.jpg";
import seventeenth from "../assets/images/17.jpg";
import eighteenth from "../assets/images/18.jpg";
import twentyFirst from "../assets/images/21.jpg";
import twentyFourth from "../assets/images/24.jpg";
import twentySixth from "../assets/images/26.jpg";
import twentyNineth from "../assets/images/29.jpg";
import thirtyFirst from "../assets/images/31.jpg";
import thirtyThird from "../assets/images/33.jpg";
import thirtyFour from '../assets/images/34.jpg';
import thirtyFive from '../assets/images/35.jpeg';
import thirtySix from '../assets/images/36.jpg';
import thirtySeven from '../assets/images/37.jpg';
import thirtyNine from '../assets/images/39.jpg';
import forty from '../assets/images/40.jpg';
import fortyOne from '../assets/images/41.jpg';

export const photoSet1 = [
  {
    src: first,
    width: 4032,
    height: 1960
  },
  {
    src: second,
    width: 1960,
    height: 4032
  },
  {
    src: fifth,
    width: 1960,
    height: 4032
  },
  {
    src: twentySixth,
    width: 1960,
    height: 4032
  },
  {
    src: third,
    width: 1960,
    height: 4032
  },
  {
    src: fourth,
    width: 4032,
    height: 1960
  }, 
  {
    src: thirteenth,
    width: 4032,
    height: 1960
  },
  {
    src: thirtySix,
    width: 2736,
    height: 3648
  },
  {
    src: thirtyFour,
    width: 4000,
    height: 3000
  },

];

export const photoUrlSet1 = [
  first, second, fifth, twentySixth, third, fourth, thirteenth, thirtySix, thirtyFour
]

export const photoSet2 = [
  {
    src: thirtyFive,
    width: 2000,
    height: 1333
  },
  {
    src: seventh,
    width: 1960,
    height: 4032
  },
  {
    src: eight,
    width: 1960,
    height: 4032
  },
  {
    src: nineth,
    width: 1960,
    height: 4032
  },
  {
    src: eleventh,
    width: 1960,
    height: 4032
  },
  {
    src: twelfth,
    width: 4032,
    height: 1960
  }, 
  {
    src: twentyNineth,
    width: 1227,
    height: 1600
  },
  {
    src: twentyFourth,
    width: 1214,
    height: 1600
  },
  {
    src: thirtySeven,
    width: 2048,
    height: 1536
  },
]

export const photoUrlSet2 = [
  thirtyFive, seventh, eight, nineth, eleventh, twelfth, twentyNineth, twentyFourth, thirtySeven
]

export const photoSet3 = [
  {
    src: eighteenth,
    width: 1500,
    height: 1000
  },
  {
    src: seventeenth,
    width: 1200,
    height: 1600
  },
  {
    src: fortyOne,
    width: 3000,
    height: 4000
  },
  {
    src: thirtyFirst,
    width: 1500,
    height: 2000,
  },
  {
    src: twentyFirst,
    width: 1960,
    height: 4032
  },
  {
    src: forty,
    width: 4000,
    height: 3000
  },

  {
    src: thirtyNine,
    width: 4000,
    height: 3000
  },
  {
    src: thirtyThird,
    width: 4032,
    height: 1960
  }
]

export const photoUrlSet3 = [
  eighteenth, seventeenth, fortyOne, thirtyFirst, twentyFirst, forty, thirtyNine, thirtyThird
]

