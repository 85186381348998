import React from "react";
import classes from "./svg.css"

const buymeacoffeeSvg = () => {
    return (
        <svg viewBox="0 0 24 24" className={classes.BuyMeACoffeSvg}>

<path d="m17.25 8h-15.5c-.965 0-1.75-.785-1.75-1.75v-.5c0-.965.785-1.75 1.75-1.75h15.5c.965 0 1.75.785 1.75 1.75v.5c0 .965-.785 1.75-1.75 1.75z"/><path d="m15.999 5.75c-.441 0-.846-.295-.965-.743l-.604-2.264c-.118-.438-.514-.743-.966-.743h-7.928c-.452 0-.849.305-.966.743l-.604 2.265c-.142.534-.695.851-1.224.708-.533-.143-.851-.69-.708-1.224l.604-2.265c.35-1.311 1.542-2.227 2.898-2.227h7.928c1.356 0 2.548.916 2.897 2.227l.604 2.265c.143.534-.175 1.082-.708 1.224-.085.023-.172.034-.258.034z"/><path d="m5.86 20h6.07l-.5 2h-5.57c-1.56 0-2.88-1.22-2.99-2.78l-.87-11.9c-.04-.55.38-1.03.93-1.07.55-.03 1.03.38 1.07.93l.86 11.89c.04.52.48.93 1 .93z"/><path d="m17 7.32-.13 1.76c-.89.2-1.63.84-1.95 1.72l-.23.62.31-4.24c.04-.55.53-.96 1.07-.93.55.04.97.52.93 1.07z"/><path d="m20.632 23.87-3.132-2.131-3.132 2.131c-.258.174-.596.173-.852-.006-.255-.178-.374-.497-.297-.798l.975-3.85-2.932-2.503c-.233-.199-.321-.52-.224-.81.098-.29.363-.492.668-.509l3.735-.211 1.354-3.69c.109-.297.391-.493.705-.493s.596.196.704.492l1.354 3.69 3.735.211c.306.018.57.219.668.509.098.291.009.611-.224.81l-2.932 2.503.975 3.85c.077.302-.042.62-.297.798-.252.178-.59.184-.851.007z"/>

        </svg>
    )
}

export default buymeacoffeeSvg;