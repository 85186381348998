import React from "react";
import classes from "./NavigationSingleItem.css";
import {NavLink} from "react-router-dom";

const navigationSingleItem = (props) => {
    return (
        <li className={classes.NavigationSingleItem}>
            <NavLink to={props.link} exact activeClassName={classes.active}>{props.value}</NavLink>
        </li>
    )
}

export default navigationSingleItem;