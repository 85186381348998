import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom";

import "./assets/fonts/RobotoMono-Thin.ttf";
import "./assets/fonts/RobotoMono-Light.ttf";
import "./assets/fonts/RobotoMono-Medium.ttf";
import "./assets/fonts/RobotoMono-Regular.ttf";
import "./assets/fonts/RobotoMono-SemiBold.ttf";
import "./assets/fonts/Nunito-ExtraLight.ttf";
import "./assets/fonts/Nunito-Light.ttf";
import "./assets/fonts/Nunito-Regular.ttf";
import "./assets/fonts/Nunito-SemiBold.ttf";
import "./assets/fonts/Lato-Light.ttf";
import "./assets/fonts/Lato-Regular.ttf";
import "./assets/fonts/Lato-Bold.ttf";


ReactDOM.render(
    <BrowserRouter>
    <App />
    </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
