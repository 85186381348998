import React from "react";
import classes from "./NavigationItems.css";
import NavigationSingleItem from "../NavigationSingleItem/NavigationSingleItem";

const navigationItems = (props) => {
    return (
        <ul className={classes.NavigationItems}>
            <NavigationSingleItem link="/" value="Home"></NavigationSingleItem>
            <NavigationSingleItem link="/projects" value="Projects"></NavigationSingleItem>
            <NavigationSingleItem link="/blog" value="Blog"></NavigationSingleItem>
            <NavigationSingleItem link="/about" value="About"></NavigationSingleItem>
        </ul>
    )
}

export default navigationItems;