import React, {Component} from "react";
import classes from "./PreviewPost.css";
import ReactHtmlParser from "react-html-parser";
import {Link} from "react-router-dom";
import Fade from "react-reveal/Fade"

class PreviewPost extends Component {
    render() {
        const {title, body, id} = this.props;
        const firstPar = body.indexOf("</p>");
        const firstFiltered = body.slice(firstPar+4);
        const secondPar = firstFiltered.indexOf("</p>");
        const preview = body.slice(0, firstPar+secondPar+4) + body.slice(firstPar+secondPar+4, firstPar+secondPar+8); 

        return (
            <div className={classes.PreviewPost}>
                <div className={classes.DesktopContainer}>
                    <Fade bottom>
                        <h1>{title}</h1>
                    </Fade>
                </div>

                <div className={classes.MobileContainer}>
                        <h1>{title}</h1>
                </div>
                
             <div className={classes.PreviewContainer}>
                 {ReactHtmlParser(preview)}
                 <Link className={classes.SpecialLink} to={`/post/${id}`}>Continue reading...</Link>
             </div>
             
        
            </div>
        )
    }
}

export default PreviewPost;