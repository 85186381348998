import React from "react";
import AuxElement from "../../hoc/AuxElement";
import classes from "./Footer.css";
import GitSvgFooter from "../../assets/svgs/gitSvgFooter";
import LinkedinSvg from "../../assets/svgs/linkedinSvg";
import MailSvg from "../../assets/svgs/mailSvg";
import BuyMeACoffeSvg from "../../assets/svgs/buymeacoffeSvg"

const footer = () => {
    return (
        <AuxElement>
            <div className={classes.Footer}>
                <div className={classes.LinksContainer}>
                    <a href="https://github.com/acaravello/" target="_blank" rel="noopener noreferrer"><GitSvgFooter /></a>
                    <a href="https://www.linkedin.com/in/antonio-caravello-a7a331145" target="_blank" rel="noopener noreferrer"><LinkedinSvg /></a>
                    <a href="mailto:acaravello13@gmail.com" target="_blank" rel="noopener noreferrer" className={classes.MailLink}><MailSvg /></a>
                    <a href="https://www.buymeacoffee.com/thecaravello" target="_blank"rel="noopener noreferrer" className={classes.CoffeeLink}><BuyMeACoffeSvg /></a>
                </div>
                <div className={classes.DoneBy}>
                   thecaravello © 2021
                </div>
            </div>
        </AuxElement>
    )
}

export default footer;